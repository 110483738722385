import client from "../../setupClient";
import { fetchMFADataQuery } from "shared/graphql/queries/mfa";

export function fetchMFAData() {
    return () => {
        return client.query({
            query: fetchMFADataQuery,
            fetchPolicy: "network-only"
        });
    };
} 

export async function fetchMFADataUnauth(email) {
    const parameters = {
        headers: {
            "content-type": "application/json; charset=UTF-8"
        },
        method: "GET"
    };
    const response = await fetch(`/register/user/mfa/${email}`, parameters);

    return await response.json();
}