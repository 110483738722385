import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { SearchInput } from "../../components/SearchInput";
import Button from "shared/components/buttons/Button";
import { useTranslation } from "react-i18next";
import TranslationModal from "components/TranslationModal";
import "./style.scss";

const Translations = () => {
    const [translationKeys, setTranslationKeys] = useState([]);
    const [translations, setTranslations] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const [t] = useTranslation();

    useEffect(() => {
        let isMounted = true;

        const logTranslationKeys = () => {
            if (!isMounted) return;

            const translations = i18next.store.data["en"]?.["OKcomply%20NL"];

            const getAllKeys = (translations, parentKey = "") => {
                let keys = [];
                for (const key in translations) {
                    if (translations.hasOwnProperty(key)) {
                        const value = translations[key];
                        const currentKey = parentKey ? `${parentKey}.${key}` : key;

                        if (typeof value === "object" && !Array.isArray(value)) {
                            keys = keys.concat(getAllKeys(value, currentKey));
                        } else {
                            keys.push(currentKey);
                        }
                    }
                }
                return keys;
            };

            const allKeys = getAllKeys(translations);

            setTranslationKeys(allKeys);
            setTranslations(translations);
        };

        if (i18next.isInitialized) {
            logTranslationKeys();
        } else {
            i18next.on("initialized", logTranslationKeys);
        }

        return () => {
            isMounted = false;
            i18next.off("initialized", logTranslationKeys);
        };
    }, []);

    const refreshTranslationKeys = () => {
        const translations = i18next.store.data["en"]?.["OKcomply%20NL"];
        const getAllKeys = (translations, parentKey = "") => {
            let keys = [];
            for (const key in translations) {
                if (translations.hasOwnProperty(key)) {
                    const value = translations[key];
                    const currentKey = parentKey ? `${parentKey}.${key}` : key;

                    if (typeof value === "object" && !Array.isArray(value)) {
                        keys = keys.concat(getAllKeys(value, currentKey));
                    } else {
                        keys.push(currentKey);
                    }
                }
            }
            return keys;
        };
        const allKeys = getAllKeys(translations);
        setTranslationKeys(allKeys);
        setTranslations(translations);
    };

    const getNestedValue = (obj, path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    };

    const formatValue = (value) => {
        if (typeof value === "object") {
            return JSON.stringify(value, null, 2);
        }
        return value || "Not available";
    };

    const openModal = (key) => {
        if (key) {
            setSelectedKey(key);
           
        } else {
            setSelectedKey("");
           
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedKey(null);
      
        setShowModal(false);
    };

    const handleFilterChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredKeys = translationKeys.filter((key) => {
        const translation = formatValue(getNestedValue(translations, key)).toLowerCase();
        return key.toLowerCase().includes(searchTerm) || translation.includes(searchTerm);
    });

    return (
        <div>
            <div className="page-divider" />
            <div className="header-container">
                <h2>{t("translation.title")}</h2>
                <div className="spacer" />
                <div className="filter-divider-translation" />
            </div>
            <div className="spacer" />

            <div className="button-container">
                <Button
                    secondary
                    label={t("Add Translation")}
                    onClick={() => openModal(null)}
                />
                {showModal && (
                    <TranslationModal
                        title={selectedKey ? `Edit Translation: ${selectedKey}` : t("Add a new key")}
                        onClose={closeModal}
                        onSuccess={refreshTranslationKeys}
                        translationId={selectedKey}
                    />
                )}
            </div>

            <div className="search-container">
                <SearchInput onChange={handleFilterChange} />
            </div>

            <div className="list-container">
                {filteredKeys.length > 0 ? (
                    <div className="translation-list">
                        {filteredKeys.map((key) => (
                            <div
                                key={key}
                                className="organisation-list-item-link"
                                onClick={() => openModal(key)}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="organisation-list-item">
                                    <div className="organisation-list-item-rows item is-row">
                                        <div className="organisation-list-item-column">
                                            <div className="item is-bold is-center is-column">
                                                {key}
                                            </div>
                                        </div>
                                        <div className="organisation-list-item-column">
                                            <div className="item is-center is-column">
                                                {formatValue(getNestedValue(translations, key))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="no-matching-translations">No matching translations found.</p>
                )}
            </div>
        </div>
    );

};

export default Translations;