import LocalStorageBackend from "i18next-localstorage-backend";
import HttpBackend from "i18next-http-backend";
import { HttpLink } from "apollo-link-http";

const browserLanguage = navigator.language || navigator.userLanguage;

const getEnvironment = () => {
    const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");
    return isDev ? "development" :
        window.location.href.includes("acceptance") ? "acceptance" :
            "production";
};

const ENVIRONMENTS = {
    development: "http://localhost:1337/",
    acceptance: "https://acceptance.okcomply.com/",
    production: "https://my.okcomply.com/"
};

export const i18nConfig = {
    react: {
        useSuspense: false
    },
    interpolation: {
        format: function (value, format) {
            if (!(typeof value === "string")) return value;
            if (format === "uppercase") return value[0].toUpperCase() + value.slice(1);
            if (format === "lowercase") return value[0].toLowerCase() + value.slice(1);
            else return value;
        },
        escapeValue: false
    },
    nl: ["OKComply%20NL"],
    preload: ["nl"],
    defaultNS: "OKcomply%20NL",
    ns: "OKcomply%20NL",
    lng: browserLanguage,
    fallbackLng: "en",
    load: "all",
    backend: {
        backends: [
            LocalStorageBackend,
            HttpBackend
        ],
        backendOptions: [
            {
                prefix: "i18next_res_",
                expirationTime: 24 * 60 * 60 * 1000,
                versions: { en: "v1.0", nl: "v1.0" }
            },
            {
                loadPath: ENVIRONMENTS[getEnvironment()] + "register/user/translation",
                parse: (data, lng) => {
                    try {
                        const parsedData = JSON.parse(data);
                        const translations = parsedData?.translation?.translations;
                        if (lng === "en") {
                            return translations.en?.["OKcomply%20NL"];
                        }
                        if (lng === "nl") {
                            return translations.nl?.["OKcomply%20NL"];
                        }
                    } catch (error) {
                        console.error("Error parsing data from HTTP backend:", error);
                        return {};
                    }
                }
            }
        ]
    },
    debug: false
};