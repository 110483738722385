import React, { useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import Button from "shared/components/buttons/Button";
import InputText from "shared/components/inputs/InputText";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { updateTranslationsAction } from "redux/actions/updateTranslationsAction";

const TranslationModal = ({ title, onClose, translationId, onSuccess }) => {

    const dispatch = useDispatch();
    const [t] = useTranslation();

    const getTranslation = (lang, ns, key) => {
        return i18next.getResource(lang, ns, key) || "Translation for a given key not found";
    };

    const [key, setKey] = useState(translationId || "");
    const [englishTranslation, setEnglishTranslation] = useState(
        translationId ? getTranslation("en", "OKcomply%20NL", translationId) : ""
    );
    const [dutchTranslation, setDutchTranslation] = useState(
        translationId ? getTranslation("nl", "OKcomply%20NL", translationId) : ""
    );

    const updateTranslationKey = async () => {
        try {
            i18next.addResource('en', 'OKcomply%20NL', key, englishTranslation, { silent: false });
            i18next.addResource('nl', 'OKcomply%20NL', key, dutchTranslation, { silent: false });
            const translations = i18next.store.data;
            const metadata = {
                updatedAt: new Date(),
            };

            await dispatch(updateTranslationsAction(translations, metadata));
            const translationsEn = i18next.store.data["en"]?.["OKcomply%20NL"];
            localStorage.setItem("i18next_res_en-OKcomply%20NL", JSON.stringify(translationsEn));
            const translationsNl = i18next.store.data["en"]?.["OKcomply%20NL"];
            localStorage.setItem("i18next_res_nl-OKcomply%20NL", JSON.stringify(translationsNl));
            onSuccess();
            onClose();


        } catch (error) {
            console.error("Error disabling MFA:", error);
        }
    };
    const removeResource = (lng, ns, key) => {
        const translations = i18next.store.data[lng]?.[ns];

        const deleteNestedKey = (obj, keys) => {
            const lastKey = keys.pop();
            const target = keys.reduce((acc, currKey) => {
                return acc ? acc[currKey] : undefined;
            }, obj);

            if (target && target.hasOwnProperty(lastKey)) {
                delete target[lastKey];
                console.log(`Translation key '${lastKey}' removed from ${ns} in language ${lng}`);

                let parent = obj;
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    if (parent[key] && Object.keys(parent[key]).length === 0) {
                        delete parent[key]; 
                        console.log(`Removed empty parent key '${key}' from ${ns} in language ${lng}`);
                    }
                    parent = parent[key]; 
                }
              
                if (Object.keys(obj).length === 0) {
                    delete i18next.store.data[lng][ns];
                    console.log(`Removed empty namespace '${ns}' for language '${lng}'`);
                }
            } else {
                console.warn(`Translation key '${lastKey}' not found in ${ns} for language ${lng}`);
            }
        };

        if (translations) {
            const keys = key.split('.');
            deleteNestedKey(translations, keys);
            const translationsEn = i18next.store.data["en"]?.["OKcomply%20NL"];
            localStorage.setItem("i18next_res_en-OKcomply%20NL", JSON.stringify(translationsEn));
            const translationsNl = i18next.store.data["en"]?.["OKcomply%20NL"];
            localStorage.setItem("i18next_res_nl-OKcomply%20NL", JSON.stringify(translationsNl));
        } else {
            console.warn(`No translations found for namespace '${ns}' in language '${lng}'`);
        }
    };

    const deleteTranslationKey = async () => {
        try {
            removeResource("en", "OKcomply%20NL", key);
            removeResource("nl", "OKcomply%20NL", key);
            const translations = i18next.store.data;
            const metadata = {
                updatedAt: new Date(),
            };

            await dispatch(updateTranslationsAction(translations, metadata));
            onSuccess();
            onClose();
        } catch (error) {
            console.error("Error deleting translation key:", error);
        }
    };

    return (
        <div className="modal-container">
            <div onClick={onClose} className="modal-background" />
            <div className="tabbed-modal">
                <header className="modal-head">
                    <h3 className="modal-title">{title}</h3>
                    <button
                        className="delete is-large"
                        aria-label="close"
                        onClick={onClose}
                    />
                </header>
                <section className="modal-body">
                    <InputText
                        label={t("Key")}
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                    />
                    <InputText
                        label={t("English Translation")}
                        value={englishTranslation}
                        onChange={(e) => setEnglishTranslation(e.target.value)}
                    />
                    <InputText
                        label={t("Dutch Translation")}
                        value={dutchTranslation}
                        onChange={(e) => setDutchTranslation(e.target.value)}


                    />
                    <div className="button-container">
                        <Button
                            spacedLeft
                            danger
                            label={t("admin.menu.button.remove.translation")}
                            onClick={deleteTranslationKey}
                        />
                        <Button
                            spacedRight
                            label={t("admin.menu.button.add.translation")}
                            onClick={updateTranslationKey}
                        />
                    </div>
                </section>
            </div >
        </div >
    );
};

TranslationModal.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default TranslationModal;